<template>
  <!-- BEGIN Page Header -->
  <header class="page-header" role="banner">
    <!-- we need this logo when user switches to nav-function-top -->
    <div class="page-logo">
      <router-link :to="{name: 'dashboard'}" v-slot="{ href, navigate}">
        <a :href="href" @click="navigate"
           class="page-logo-link press-scale-down d-flex align-items-center position-relative"
           data-toggle="modal" data-target="#modal-shortcut">
          <img :src="publicPath + 'media/logo.svg'" class="logo-edutalk" alt="SmartAdmin WebApp"
               aria-roledescription="logo">
          <span class="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
        </a>
      </router-link>
    </div>
    <!-- DOC: nav menu layout change shortcut -->
    <div class="hidden-md-down dropdown-icon-menu position-relative">
      <a href="#" class="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden"
         title="Hide Navigation">
        <i class="ni ni-menu"></i>
      </a>
      <ul>
        <li>
          <a href="#" class="btn js-waves-off" data-action="toggle" data-class="nav-function-minify"
             title="Minify Navigation">
            <i class="ni ni-minify-nav"></i>
          </a>
        </li>
        <li>
          <a href="#" class="btn js-waves-off" data-action="toggle" data-class="nav-function-fixed"
             title="Lock Navigation">
            <i class="ni ni-lock-nav"></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- DOC: mobile button appears during mobile width -->
    <div class="hidden-lg-up">
      <a href="#" class="header-btn btn press-scale-down" @click="openMenuMobile" data-action="toggle"
         data-class="mobile-nav-on">
        <i class="ni ni-menu"></i>
      </a>
    </div>
    <div class="search">

    </div>
    <div class="ml-auto d-flex">
      <div v-bind:class="{ show : menuNotification }" @blur="menuNotification = false">
        <a href="#" class="header-icon" @click="toggleMenuNotification(menuNotification)" data-toggle="dropdown"
           title="You got 12 notifications" :aria-expanded="menuNotification">
          <i class="fal fa-bell"></i>
<!--          <span class="badge badge-icon"></span>-->
        </a>
        <div class="dropdown-menu dropdown-menu-animated dropdown-xl">
          <div
              class="dropdown-header bg-trans-gradient d-flex justify-content-center align-items-center rounded-top mb-2">
            <h4 class="m-0 text-center color-white text-break">
              <small class="mb-0 opacity-80">Thông báo của bạn</small>
            </h4>
          </div>
          <div class="tab-content tab-notification"
          >
            <div class="tab-pane" v-bind:class="{ active: tabFeeds}" id="tab-feeds" role="tabpanel" v-loading="loading">
              <div class="custom-scroll overflow-auto h-100" id="notification-container">
              </div>
            </div>
            <div class="tab-pane" v-bind:class="{ active: tabCalendar}" id="tab-events" role="tabpanel">
              <div class="d-flex flex-column h-100">
                <edutalk-calendar></edutalk-calendar>
                <div class="flex-1 custom-scroll">
                  <div class="p-2">
                    <div class="d-flex align-items-center text-left mb-3">
                      <div class="width-5 fw-300 text-primary l-h-n mr-1 align-self-start fs-xxl">
                        15
                      </div>
                      <div class="flex-1">
                        <div class="d-flex flex-column">
                                                                    <span class="l-h-n fs-md fw-500 opacity-70">
                                                                        October 2020
                                                                    </span>
                          <span class="l-h-n fs-nano fw-400 text-secondary">
                                                                        Friday
                                                                    </span>
                        </div>
                        <div class="mt-3">
                          <p>
                            <strong>2:30PM</strong> - Doctor's appointment
                          </p>
                          <p>
                            <strong>3:30PM</strong> - Report overview
                          </p>
                          <p>
                            <strong>4:30PM</strong> - Meeting with Donnah V.
                          </p>
                          <p>
                            <strong>5:30PM</strong> - Late Lunch
                          </p>
                          <p>
                            <strong>6:30PM</strong> - Report Compression
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
              class="py-2 px-3 bg-faded d-block rounded-bottom text-right border-faded border-bottom-0 border-right-0 border-left-0">
            <router-link class="fs-xs fw-500 ml-auto" :to="{ name: 'notification-all' }">
              Xem tất cả thông báo
            </router-link>
          </div>
        </div>
      </div>
      <!-- app user menu -->
      <div v-bind:class="{ show : menuProfile }" @blur="menuProfile = false">
        <a href="#" @click.prevent="toggleMenuProfile(menuProfile)" data-toggle="dropdown"
           :title="currentUser.email"
           :aria-expanded="menuProfile"
           class="header-icon d-flex align-items-center justify-content-center ml-2">
          <img :src="currentUser.image ? currentUser.image : publicPath + 'media/default-avatar.png'"
               class="profile-image rounded-circle"
               alt="">
        </a>
        <div class="dropdown-menu dropdown-menu-animated dropdown-lg show" x-placement="bottom-start"
             style="position: absolute; top: 66px; left: 1627px; will-change: top, left;">
          <div class="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
            <div class="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                                            <span class="mr-2">
                                                <img
                                                    :src="currentUser.image ? currentUser.image : publicPath + 'media/default-avatar.png'"
                                                    class="rounded-circle profile-image" alt="">
                                            </span>
              <div class="info-card-text">
                <div class="fs-lg text-truncate text-truncate-lg">{{ currentUser.name }}</div>
                <span class="text-truncate text-truncate-md opacity-80">{{ currentUser.email }}</span>
              </div>
            </div>
          </div>
          <div class="dropdown-divider m-0"></div>
          <router-link
              to="/profile"
              v-slot="{ href, navigate }"
          >
            <a :href="href" class="dropdown-item" data-action="app-reset" @click="navigate">
              <span data-i18n="drpdwn.reset_layout">Thông tin cá nhân</span>
            </a>
          </router-link>

          <div class="dropdown-divider m-0"></div>
          <a class="dropdown-item fw-500 pt-3 pb-3" @click="logOut">
            <span data-i18n="drpdwn.page-logout">Đăng xuất</span>
          </a>
        </div>
      </div>
    </div>
  </header>
  <!-- END Page Header -->
</template>

<script>
import {mapGetters} from "vuex";
import EdutalkCalendar from "@/views/content/edutalk-calendar.vue";
import {LOGOUT} from "@/core/services/store/auth.module";

export default {
  name: "EdutalkHeader",
  components: {
    EdutalkCalendar
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      menuProfile: false,
      menuNotification: false,
      menuShort: false,
      tabFeeds: true,
      tabCalendar: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "currentUser", "page", "links"]),
  },
  created() {
    document.addEventListener('click', this.closePopupHeader);
  },
  mounted() {
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closePopupHeader)
  },
  methods: {
    closePopupHeader(e) {
      if (!this.$el.contains(e.target)) {
        this.menuProfile = false;
        this.menuNotification = false;
        this.menuShort = false;
      }
    },

    resetShowMenu() {
      this.menuProfile = false;
      this.menuNotification = false;
      this.menuShort = false;
    },
    toggleMenuProfile(menuProfile) {
      this.resetShowMenu();
      this.menuProfile = !menuProfile
    },
    toggleMenuNotification(menuNotification) {
      this.resetShowMenu();
      this.menuNotification = !menuNotification
    },
    toggleMenuShort(menuShort) {
      this.resetShowMenu();
      this.menuShort = !menuShort
    },
    activeTab(tab) {
      if (tab === 'tabCalendar') {
        this.tabCalendar = true;
        this.tabFeeds = false;
      } else {
        this.tabCalendar = false;
        this.tabFeeds = true;
      }
    },
    logOut() {
      this.$store.dispatch(LOGOUT).then(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('_token');
        this.$router.push({name: 'login'})
      });
    },
    redirectLink(item) {
      if (item.flag_old) {
        // Case : cập nhật phiếu lương bên educenter.
        if (item.type == 1) {
          window.location = this.links[item.type];
        } else {
          if (item.url) window.location = item.url;
        }
      } else window.location = this.links[item.type];
    },
    openMenuMobile() {
      document.body.classList.add("mobile-nav-on");
      let backdrop = document.querySelector(".hideBackDrop");
      if (backdrop.classList.contains("hide")) {
        backdrop.classList.remove('hide');
        backdrop.classList.add('add');
      }
    },
  },
};
</script>
<style>
.isRead {
  background: #ffffff !important;
}
</style>
