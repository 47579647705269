<template>
    <nav class="shortcut-menu d-none d-sm-block">
        <input type="checkbox" class="menu-open" name="menu-open" id="menu_open">
        <label for="menu_open" class="menu-open-button ">
            <span class="app-shortcut-icon d-block"></span>
        </label>
        <a href="#" class="menu-item btn waves-effect waves-themed" data-toggle="tooltip" data-placement="left" title="" data-original-title="Scroll Top">
            <i class="fal fa-arrow-up"></i>
        </a>
        <a href="tel:19004788" class="menu-item btn waves-effect waves-themed" data-toggle="tooltip" data-placement="left" title="" data-original-title="Gọi hỗ trợ">
            <i class="fal fa-phone"></i>
        </a>
        <a href="https://hotro.edutalk.edu.vn" class="menu-item btn waves-effect waves-themed" data-action="app-print" data-toggle="tooltip" data-placement="left" title="" data-original-title="Trung tâm hỗ trợ">
            <i size="medium" class="el-icon-service"></i>
        </a>
    </nav>
</template>

<script>
    export default {
        name: "quick-menu"
    }
</script>
