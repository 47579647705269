<template>
    <ol class="breadcrumb page-breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0);">Edutalk</a></li>
        <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`" v-for="(breadcrumb, i) in breadcrumbs">
            <router-link
                    v-if="breadcrumb.route"
                    :key="i"
                    :to="{ name: breadcrumb.route }"
                    class="text-muted">
                {{ breadcrumb.title }}

            </router-link>
            <span class="text-muted" :key="i" v-if="!breadcrumb.route">
                {{ breadcrumb.title }}
              </span>
        </li>
        <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date">{{ now }}</span></li>
    </ol>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        name: "EdutalkBreadcrumbs",
        data() {
            return {
                publicPath: process.env.VUE_APP_BASE_URL,
                now: null
            }
        },
        props: {
            breadcrumbs: Array,
            title: String
        },
        mounted() {
            this.now = this.$moment().format('dddd - D/M/Y');
        },
        computed: {
            ...mapGetters(["layoutConfig"]),
        },
    };
</script>
