<template>
    <footer class="page-footer" role="contentinfo">
        <div class="d-flex align-items-center flex-1 text-muted">
            <span class="hidden-md-down fw-700">2018 - {{ $moment().format('YYYY') }} © Edutalk Technology by&nbsp;<a href="https://edutalk.edu.vn" class="text-primary fw-500" title="edutalk.edu.vn" target="_blank">edutalk.edu.vn</a></span>
        </div>
        <div>
            <ul class="list-table m-0">
                <li class="pl-3"><a href="#" class="text-secondary fw-700">Documentation</a></li>
                <li class="pl-3 fs-xl"><a href="#" class="text-secondary" target="_blank"><i class="fal fa-question-circle" aria-hidden="true"></i></a></li>
            </ul>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "edutalk-footer"
    }
</script>
