<template>
    <div class="h-auto">
        <table class="table table-bordered table-calendar m-0 w-100 h-100 border-0">
            <tr>
                <th colspan="7" class="pt-3 pb-2 pl-3 pr-3 text-center">
                    <div class="js-get-date h5 mb-2">{{ $moment().format("dddd, DD-MM-YYYY") }}</div>
                </th>
            </tr>
            <tr class="text-center">
                <th>CN</th>
                <th>T2</th>
                <th>T3</th>
                <th>T4</th>
                <th>T5</th>
                <th>T6</th>
                <th>T7</th>
            </tr>
            <tr>
                <td class="text-muted bg-faded">30</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>
                    <i class="fal fa-birthday-cake mt-1 ml-1 position-absolute pos-left pos-top text-primary"></i>
                    6
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td class="bg-primary-300 pattern-0">10</td>
                <td>11</td>
                <td>12</td>
                <td>13</td>
            </tr>
            <tr>
                <td>14</td>
                <td>15</td>
                <td>16</td>
                <td>17</td>
                <td>18</td>
                <td>19</td>
                <td>20</td>
            </tr>
            <tr>
                <td>21</td>
                <td>22</td>
                <td>23</td>
                <td>24</td>
                <td>25</td>
                <td>26</td>
                <td>27</td>
            </tr>
            <tr>
                <td>28</td>
                <td>29</td>
                <td>30</td>
                <td>31</td>
                <td class="text-muted bg-faded">1</td>
                <td class="text-muted bg-faded">2</td>
                <td class="text-muted bg-faded">3</td>
            </tr>
        </table>
    </div>
</template>

<script>
    export default {
        name: "EdutalkCalendar",
        props: {
            event: []
        },
        data() {
            return {
            }
        },
        mounted() {
        }
    };
</script>
